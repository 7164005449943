$primary: var(--primary, #19b394);
$background: var(--background, #fff);
$foreground: var(--foreground, #161616);
$red: var(--red, #e60a3e);
$shadow-medium: rgba(0, 0, 0, 0.1);

$font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

$base-unit: var(--base-unit, 8px);

@function calcSize($multiplier) {
  $result: calc(#{$multiplier} * #{$base-unit});
  @return $result;
}

.sv-save-data_root {
  position: absolute;
  left: 50%;
  bottom: calcSize(3);
  transform: translate(-50%, 0);
  background: $background;
  opacity: 0.75;
  padding: calcSize(3) calcSize(6);
  box-shadow: 0px 2px 6px $shadow-medium;
  border-radius: calcSize(1);
  color: $foreground;
  min-width: calcSize(30);
  text-align: center;
  z-index: 1600; //todo: should overlap popup

  font-family: var(--font-family, $font-family);
  font-size: calcSize(2);
  line-height: calcSize(3);
  white-space: nowrap;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sv-save-data_root.sv-save-data_error {
  background-color: $red;
  color: $background;
  opacity: 1;
  font-weight: 600;
  padding: calcSize(2) calcSize(2) calcSize(2) calcSize(6);
  gap: calcSize(6);
}

.sv-save-data_root.sv-save-data_error .sv-save-data_button {
  font-weight: 600;
  font-size: calcSize(2);
  line-height: calcSize(3);
  height: calcSize(5);
  color: #ffffff;
  background-color: $red;
  border: calcSize(0.25) solid #ffffff;
  border-radius: calcSize(0.75);
  padding: calcSize(1) calcSize(3);
  display: flex;
  align-items: center;
}
.sv-save-data_root.sv-save-data_error .sv-save-data_button:hover,
.sv-save-data_root.sv-save-data_error .sv-save-data_button:focus {
  color: $red;
  background-color: $background;
}

.sv-save-data_root.sv-save-data_success {
  background-color: $primary;
  color: #ffffff;
  opacity: 1;
  font-weight: 600;
}
