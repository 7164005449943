sv-brand-info {}

.sv-brand-info {
  width: 100%;
  font-family: var(--font-family, $font-family);
  text-align: center;
  color: #161616;
  padding: 150px 32px 150px 32px;
  background: white;

  a {
    color: #161616;
  }
}

.sv-brand-info__text {
  line-height: 24px;
  font-size: 16px;
}

.sv-brand-info__logo {
  img {
    width: 200px;
  }

  display: inline-block;
}

.sv-brand-info__terms {
  line-height: 16px;
  font-size: 12px;
}