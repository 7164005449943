@import "../variables.scss";



.sd-btn {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: calcSize(2) calcSize(6);
  background: $background;
  box-shadow: 0px 1px 2px $shadow-inner;
  border-radius: calcSize(0.5);
  cursor: pointer;

  font-family: var(--font-family, $font-family);
  font-style: normal;
  font-weight: 600;
  font-size: calcSize(2);
  line-height: calcSize(3);
  text-align: center;
  color: $primary;
  border: 2px solid transparent;
  outline: none;
}

.sd-btn:not(:disabled) {

  &:hover,
  &:focus {
    box-shadow: 0 0 0 2px $primary;
  }
}

.sd-btn:disabled {
  color: $foreground-disabled;
  cursor: default;
}

.sd-btn--action {
  background-color: $primary;
  color: $primary-foreground;
}

.sd-btn--action:not(:disabled) {

  &:hover,
  &:focus {
    color: $primary;
    background-color: $background;
  }
}

.sd-btn--action:disabled {
  color: $primary-foreground-disabled;
}