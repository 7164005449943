.sd-timer {
  z-index: 2;
  float: right;
  position: sticky;
  bottom: calc(var(--sd-timer-size) / 144 * 32);
  background: white;
  width: var(--sd-timer-size);
  height: var(--sd-timer-size);
  margin-right: calc(var(--sd-timer-size) / 144 * 32);
  display: flex;
  border-radius: 100%;
  padding: calc(var(--sd-timer-size) / 144 * 8);
  filter: drop-shadow(0px calcSize(1) calcSize(2) $shadow-medium);
  box-shadow: 0 2px 6px #0000001a;
  box-sizing: border-box;
}

.sd-timer--top {
  top: calc(var(--sd-timer-size) / 144 * 32);
  margin-top: calcSize(4);
  margin-bottom: calc(-1 * var(--sd-timer-size));
}
.sd-timer--bottom {
  bottom: calc(var(--sd-timer-size) / 144 * 32);
  margin-top: calc(var(--sd-timer-size) * ( -1 - 32 / 144));
}
.sd-timer__progress {
  --sd-timer-stroke-background-color:  var(--background-dim, #f3f3f3);
  --sd-timer-stroke-background-width: 2px;
  stroke-linecap: round;
  height: 100%;
  width: 100%;
  transform: rotate(-90deg);
  stroke: $primary;  
  stroke-dashoffset: 0;
  fill: none;
  stroke-width: 4px;
}
.sd-timer__progress--animation {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 1s linear;
}
.sd-timer__text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: calcSize(1);
  color: $primary;
  font-weight: 700;
  font-size: calc(var(--sd-timer-size) / 144 * 32);
}
.sd-timer__text--major {
  color: $primary;
  font-weight: 700;
  font-size: calc(var(--sd-timer-size) / 144 * 32);
}
.sd-timer__text--minor {
  color: $foreground-light;
  font-weight: 600;
  font-size: calcSize(1.5);
}