.sd-image__image {
  display: block;
  max-width: 100%;
  border-radius: calcSize(0.5);
}

.sd-image__image--adaptive {
  width: 100%;
  height: auto;
  max-width: calcSize(80);
}
